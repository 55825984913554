import request from '@/utils/request'

// export function pageListReiForm(pageCurrent, pageSize, formNo, formStatus, summary, bizType, beginDate, endDate) {
export function pageListReiForm(pageCurrent, pageSize, summary, formStatus) {
  return request({
    url: 'reimburse/reiForm/pageList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: {
      'pageCurrent': pageCurrent,
      'pageSize': pageSize,
      'summary': summary,
      'formStatus': formStatus
    }
  })
}

export function getSysDictData(typeName) {
  return request({
    url: 'system/dict/dictDatas/' + typeName,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
// 报销单作废
export function cancelReiForm(data) {
  return request({
    url: 'reimburse/reiForm/cancelReiForm',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}
// 报销单撤回到草稿环节
export function toDraftForm(data) {
  return request({
    url: 'reimburse/reiForm/toDraftReiForm',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}
// 查询待办任务个数
export function getToDoCount() {
  return request({
    url: 'reimburse/reiForm/waitToDo',
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
