<!-- 开票 -->
<template>
  <div id="electricityFee">
    <div>
      <!-- 头部地理位置和搜索框 -->
      <div class="head">
        <!-- 顶部NavBar 开始 -->
        <van-nav-bar
          title="开票"
          left-text="返回"
          left-arrow
          style="background-color: rgba(255,255,255,0);"
          @click-left="onClickLeft"
        />
      </div>
    </div>
    <div class="outboxstyle">
      <div class="boxstyle">
        <p>发票抬头</p>
        <van-field
          readonly
          clickable
          :value="value"
          placeholder="选择校区"
          class="picker"
          @click="showPicker = true"
        />
        <van-popup v-model="showPicker" round position="bottom">
          <van-picker
            show-toolbar
            :columns="columns"
            @cancel="showPicker = false"
            @confirm="onConfirm"
          />
        </van-popup>
      </div>
      <img :src="imageL[0]" style="width: 87%;left: 6.5%;position: absolute;">
      <div class="boxstylemoney">
        <p>发票信息</p>
        <div>项目:<span>电费</span></div>
        <div>开票时间:<span>2020/03/10 10:21</span></div>
        <div>发票金额:<span style="color:red">¥5000.00</span></div>
      </div>
    </div>
    <div class="bottom">
      <van-button type="info" size="large" class="bottombutton" @click="dealwith">下一步</van-button>
    </div>
    <!-- 回到顶部按钮 -->
    <!-- <v-top /> -->
  </div>
</template>

<script>
import { pageListReiForm } from '@/api/reiform'
import moment from 'moment/moment'
// import ReiTabbar from '../components/ReiTabbar.vue'
export default {
  name: 'Project',
  components: {
    // ReiTabbar
  },
  data() {
    return {
      value: '易缴费',
      showPicker: false,
      columns: ['易缴费'],
      conheight: {
        height: '',
        width: '100%',
        overflow: 'auto',
        // background: "#EFF6FF"
        background: 'transparent'
      },
      imageL: [
        require('../../assets/newImages/line.png')
      ]
    }
  },
  watch: {
    summary() {
      console.log(1)
      this.getList()
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background: #F5F5F5')
  },
  created() {
    // window.addEventListener("resize", this.getHeight);
    // this.getHeight();
    this.getList()
    // 判断时间
    const now = new Date()
    const hour = now.getHours()
    let month = now.getMonth() + 1
    let date = now.getDate()
    if (month < 10) month = '0' + month
    if (date < 10) date = '0' + date
    now.setTime(now.getTime())
    var s2 = now.getFullYear() + '/' + month + '/' + date
    this.nowdata = s2
    if (hour < 6) {
      this.time = '凌晨好！'
    } else if (hour < 9) {
      this.time = '早上好！'
    } else if (hour < 12) {
      this.time = '上午好！'
    } else if (hour < 14) {
      this.time = '中午好！'
    } else if (hour < 17) {
      this.time = '下午好！'
    } else if (hour < 19) {
      this.time = '傍晚好！'
    } else if (hour < 22) {
      this.time = '晚上好！'
    } else {
      this.time = '夜里好！'
    }
  },
  mounted() {
    this.getUserInfo()
  },
  methods: {
    onConfirm(value) {
      this.value = value
      this.showPicker = false
    },
    // 动态设置高度
    // getHeight() {
    //   this.conheight.height = window.innerHeight - 170 + "px";
    // },
    // 上拉加载数据
    oncheck() {
      // 上拉调用此函数
      setTimeout(() => {
        this.pageCurrent = this.pageCurrent + 1
        pageListReiForm(
          this.pageCurrent,
          this.page,
          this.summary,
          this.formStatus
        ).then(response => {
          if (response.data.records === '') {
            this.upFinished = true
            this.$toast('没有数据了') // 弹出
            this.loading = false
            this.conheight.background = '#EFF6FF'
            return false
          } else {
            this.list = this.list.concat(response.data.records)
            for (var i = 0; i < this.list.length; i++) {
              // console.log(this.list[i].currentStepLimit)
              const titleString = this.list[i].summary
              if (!titleString) {
                return ''
              }
              if (this.summary && this.summary.length > 0) {
                // 匹配关键字正则
                const replaceReg = new RegExp(this.summary, 'g')
                // 高亮替换v-html值
                const replaceString =
                  '<pre class="search-text">' + this.summary + '</pre>'
                // 开始替换
                this.list[i].summary = this.list[i].summary.replace(
                  replaceReg,
                  replaceString
                )
              }
              this.list[i].currentStepLimit = moment(
                this.list[i].currentStepLimit
              ).format('YYYY/MM/DD HH:mm:ss')
            }
            this.$toast(this.$t('msg.loadSuccess')) // 弹出
            this.isUpLoading = false
          }
        })
      }, 500)
    },
    // 返回
    onClickLeft() {
      this.$router.go(-1)
    },
    // 获取数据
    getList() {
      this.hidebox = false
      this.loading = true
      this.divdas = false
      this.showvacuous = false
      this.pageCurrent = 1
      this.conheight.background = 'transparent'
      setTimeout(() => {
        pageListReiForm(
          this.pageCurrent,
          this.page,
          this.summary,
          this.formStatus
        ).then(response => {
          this.list = response.data.records
          this.loading = false
          this.conheight.background = '#EFF6FF'
          if (this.list.length === 0) {
            this.showvacuous = true
          } else {
            for (var i = 0; i < this.list.length; i++) {
              this.list[i].currentStepLimit = moment(
                this.list[i].currentStepLimit
              ).format('YYYY/MM/DD HH:mm:ss')
              const numm =
                (Date.parse(this.nowdata) -
                  Date.parse(this.list[i].currentStepLimit.slice(0, 10))) /
                  1000 /
                  3600 /
                  24 +
                ''
              this.numlist.push(parseInt(numm))
            }
            for (let i = 0; i < this.list.length; i++) {
              const titleString = this.list[i].summary
              if (!titleString) {
                return ''
              }
              if (this.summary && this.summary.length > 0) {
                // 匹配关键字正则
                const replaceReg = new RegExp(this.summary, 'g')
                // 高亮替换v-html值
                const replaceString =
                  '<pre class="search-text">' + this.summary + '</pre>'
                // 开始替换
                this.list[i].summary = this.list[i].summary.replace(
                  replaceReg,
                  replaceString
                )
              }
            }
          }
          this.hidebox = true
        })
      }, 1000)
    },
    // 获取用户信息
    getUserInfo() {
      var msg = JSON.parse(localStorage.getItem('UserMsg'))
      this.userName = msg.name
    },
    // 带参数跳转页面
    dealwith(item) {
      // this.$router.push({
      //   path: '/person'
      // })
    }
  }
}
</script>
<style scoped lang="scss">
#electricityFee {
  position: relative;
  background-color: #eff6ff;
  .head {
    height: 86px;
    width: 100%;
    background:linear-gradient(135deg, #3793e5 0%, #4046a6 100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.outboxstyle {
  padding: 10px;
  background-color: #F5F5F5;
  border-radius: 10px;
  margin-top: -20px;
  margin-bottom: 80px;
  width: 100%;
  height: 100%;
}
.boxstyle {
  margin: 10px 15px 0 15px;
  padding:20px 0;
  background-color: #ffffff;
  border: 1px white solid;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 4px 10px #dde9f3;
  p{
    font-size:16px;
    font-weight:700;
    margin: 20px;
  }
}
.boxstylemoney{
  margin: 10px 15px 15px;
  padding:20px 0;
  background-color: #ffffff;
  border: 1px white solid;
  border-radius: 10px;
  box-shadow: 0px 4px 10px #dde9f3;
  div{
    font-size:14px;
    margin: 20px 20px 10px 20px;
    color:#666;
    span{
      float:right;
      color:#333;
    }
  }
  p{
    font-size:16px;
    font-weight:700;
    margin: 20px 20px 10px 20px;
  }
}
.lastbattry{
  border-left: 4px #1989fa solid;
  padding-left: 10px;
  margin: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  span{
    color: #1989fa;
    float: right;
  }
}
.paymonry{
  border-left: 4px #1989fa solid;
  padding-left: 10px;
  margin: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  span{
    color: red;
    float: right;
  }
}
.picker{
  border: 1px solid #eee;
  border-radius: 5px;
  margin: 10px 20px;
  width: 90%;
  padding: 5px;
}
.buttontext{
  width:90px;
  border-radius:5px
}
.bottom{
  width: 100%;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  height: 80px;
}
.bottombutton{
  margin: 20px;
  width:90%;
  height: 40px;
}
</style>
<style>
.van-nav-bar .van-icon {
    color: #ffffff;
}
.van-nav-bar__title {
    color: #ffffff;
}
.van-nav-bar__text {
    color: #ffffff;
}
[class*='van-hairline']::after {
    border: none;
}
/* .van-cell {
    border: 1px solid #eee;
    border-radius: 5px;
    margin: 10px 20px;
    width: 90%;
} */
/* .van-field__control {
    height: 15px;
} */
.van-nav-bar {
  height: 80px;
}
</style>
